<template>
  <div class="w-5/12">
    <SwdRemoteSearch class="w-5/12" />
  </div>
  <div class="w-2/12 flex justify-center">
    <InlineSvg :src="IrisLogoStandart" width="100" height="40" />
  </div>
  <div class="w-5/12 flex justify-end">
    <SelectAdvisors />
    <HeaderNotificationsBlock class="mx-4" />
    <UserAction />
  </div>
</template>

<script>
import SwdRemoteSearch from '@/components/Global/SwdRemoteSearch.vue'
import IrisLogoStandart from '@/assets/svg/iris-logo-standard.svg'
import SelectAdvisors from '@/components/Header/SelectAdvisors.vue'
import HeaderNotificationsBlock from '@/components/Header/HeaderNotificationsBlock.vue'
import UserAction from '@/components/UserAction.vue'

export default {
  name: 'AssistantHeader',
  components: {
    UserAction,
    SwdRemoteSearch,
    HeaderNotificationsBlock,
    SelectAdvisors,
  },
  setup() {
    const storedData = sessionStorage.getItem('role')
    const parsedData = JSON.parse(storedData)
    const role = parsedData.globalComponents.role
    const storedTRCMData = sessionStorage.getItem('TRCMData')
    const parsedTRCMData = JSON.parse(storedTRCMData)
    const email = parsedTRCMData.globalComponents.TRCMData.email
    const username = email.split('@')[0]

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'user_data_push',
      iris_user: username,
      iris_user_type: role,
    })
    return {
      IrisLogoStandart,
    }
  },
}
</script>
