<template>
  <div class="w-5/12">
    <SwdRemoteSearch v-if="getRoleAccess" class="w-5/12" />
  </div>
  <div class="w-2/12 flex justify-center">
    <InlineSvg :src="IrisLogoStandart" width="100" height="40" />
  </div>
  <div class="w-5/12 flex justify-end">
    <template v-if="showContent.showNewLeadBtn">
      <NewProspectBtn class="mr-2" />
      <NewLeadModal />
    </template>
    <NewTRCMbtn v-if="trcmURL" />
    <template v-if="showContent.showNewLeadBtn">
      <NewOpportunityBtn class="mr-2" />
    </template>
    <Tasks title="Tasks" />
    <Calendar class="mr-2" title="Calendar" />
    <HeaderNotificationsBlock class="mr-4" />

    <UserAction />
  </div>
</template>

<script>
import NewLeadModal from '@/components/Modal/NewLeadModal.vue'
import NewProspectBtn from '@/components/Header/NewProspectBtn.vue'
import Calendar from '@/components/Header/Calendar.vue'
import Tasks from '@/components/Header/Tasks.vue'
import NewOpportunityBtn from '@/components/Header/NewOpportunityBtn.vue'
import UserAction from '@/components/UserAction.vue'
import SwdRemoteSearch from '@/components/Global/SwdRemoteSearch.vue'
import HeaderNotificationsBlock from '@/components/Header/HeaderNotificationsBlock.vue'
import IrisLogoStandart from '@/assets/svg/iris-logo-standard.svg'
import { useShowContentEnv } from '@/hooks/use-show-content-env'
import NewTRCMbtn from '../NewTRCMbtn.vue'
import store from '@/store'
import { computed } from 'vue'
import ROLE from '../../../enums/roles'

export default {
  name: 'AdvisorHeader',
  components: {
    UserAction,
    SwdRemoteSearch,
    HeaderNotificationsBlock,
    NewLeadModal,
    NewOpportunityBtn,
    NewTRCMbtn,
    NewProspectBtn,
    Calendar,
    Tasks,
  },
  setup() {
    const { showContent } = useShowContentEnv()
    const { trcmURL } = store.state.globalComponents
    const storedData = sessionStorage.getItem('role')
    const parsedData = JSON.parse(storedData)
    const role = parsedData.globalComponents.role
    const storedTRCMData = sessionStorage.getItem('TRCMData')
    const parsedTRCMData = JSON.parse(storedTRCMData)
    const email = parsedTRCMData.globalComponents.TRCMData.email
    const username = email.split('@')[0]

    const getRoleAccess = computed(() => {
      return store.state.globalComponents.currentTypeUser !== ROLE.LEAD ? true : false
    })

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'user_data_push',
      iris_user: username,
      iris_user_type: role,
    })
    return {
      IrisLogoStandart,
      showContent,
      getRoleAccess,
      trcmURL,
    }
  },
}
</script>
