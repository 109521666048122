<template>
  <aside class="bg-primary min-h-screen flex-col w-[5%] sm:flex hidden content minWidth">
    <div class="fixed">
      <router-link v-if="!isClientRole" :to="isClientRole ? null : { name: `home` }">
        <div class="flex items-center justify-center mt-2 w-[65px]">
          <InlineSvg :src="IrisIconReverse" width="50" height="50" />
        </div>
      </router-link>
      <div v-else class="flex items-center justify-center mt-2 w-[65px]">
        <InlineSvg :src="IrisIconReverse" width="50" height="50" />
      </div>
    </div>
    <div class="flex flex-col items-center flex-grow w-[65px] fixed top-1/4">
      <router-link v-if="$can('client', 'all')" :to="{ name: `client/member-details`, params: { id: memberId } }">
        <InlineSvg :src="IconDashboardActive" />
      </router-link>
    </div>
    <div v-if="isShowSideBar" class="flex flex-col items-center flex-grow w-[65px] fixed top-1/4">
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Dashboard"
        hide-after="0"
        show-after="2"
        placement="top-start"
      >
        <router-link
          v-if="$can('advisor', 'all') || $can('support', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: `${getType}/dashboard` }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: getRouteName === `${getType}/dashboard` }"
        >
          <InlineSvg v-if="getRouteName === `${getType}/dashboard`" :src="IconDashboardActive" />
          <InlineSvg v-else :src="IconDashboard" />
        </router-link>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Households"
        hide-after="0"
        show-after="2"
        placement="top-start"
      >
        <router-link
          v-if="$can('advisor', 'all') || $can('support', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: `${getType}/all` }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{
            active: getActiveListOfHouseholds,
          }"
        >
          <InlineSvg v-if="getActiveListOfHouseholds" :src="IconListActive" />
          <InlineSvg v-else :src="IconList" />
        </router-link>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Prospects"
        hide-after="0"
        show-after="2"
        placement="top-start"
      >
        <router-link
          v-if="$can('advisor', 'all') || $can('support', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: `${getType}/all-leads` }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: isLeadRoute }"
        >
          <InlineSvg :src="leadIcon" />
        </router-link>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Appointment Stage"
        hide-after="0"
        show-after="2"
        placement="top-start"
      >
        <router-link
          v-if="$can('advisor', 'all') || $can('support', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: `${getType}/all-pre-leads` }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: getRouteName === 'pre-leads' }"
        >
          <InlineSvg v-if="getRouteName === `${getType}/all-pre-leads`" :src="IconProspectActive" />
          <InlineSvg v-else :src="IconProspect" />
        </router-link>
      </el-tooltip>
      <el-tooltip class="box-item" effect="dark" content="Revenue" hide-after="0" show-after="2" placement="top-start">
        <router-link
          v-if="$can('advisor', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: 'pipeline' }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: getRouteName === 'pipeline' }"
        >
          <InlineSvg v-if="getRouteName === 'pipeline'" :src="IconActivityPipeLine" />
          <InlineSvg v-else :src="IconPipeLine" />
        </router-link>
      </el-tooltip>
      <el-tooltip class="box-item" effect="dark" content="Activity" hide-after="0" show-after="2" placement="top-start">
        <router-link
          v-if="$can('advisor', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: 'activity' }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: getRouteName === 'activity' }"
        >
          <el-icon :color="getRouteName === 'activity' ? 'white' : '#677494'" :size="24">
            <Clock />
          </el-icon>
        </router-link>
      </el-tooltip>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Assistant Log"
        hide-after="0"
        show-after="2"
        placement="top-start"
      >
        <router-link
          v-if="$can('advisor', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: 'logs' }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: getRouteName === 'logs' }"
        >
          <InlineSvg v-if="getRouteName === 'logs'" :src="IconLogsActive" width="20" height="20" />
          <InlineSvg v-else :src="IconLogs" width="20" height="20" />
        </router-link>
      </el-tooltip>
      <el-tooltip class="box-item" effect="dark" content="Schwab" hide-after="0" show-after="2" placement="top-start">
        <router-link
          v-if="$can('advisor', 'all') || $can('support', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: `${getType}/account` }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{
            active: getActivedefault,
          }"
        >
          <span
            v-if="isSchwabRouteAllowed"
            class="text-white font-semibold centerText"
            style="font-size: 1.2em; font-family: Arial"
          >
            S
          </span>
          <span v-else class="text-icon-not-active centerText" style="font-size: 1.2em; font-family: Arial"> S </span>
        </router-link>
      </el-tooltip>
      <el-tooltip class="box-item" effect="dark" content="Annuity" hide-after="0" show-after="2" placement="top-start">
        <router-link
          v-if="$can('advisor', 'all') || $can('support', 'all') || $can('ceo', 'all') || $can('admin', 'all')"
          :to="{ name: 'annuity' }"
          class="item flex justify-center items-center cursor-pointer w-full h-12"
          :class="{ active: getRouteName === 'annuity' }"
        >
          <span
            v-if="isAnnuityRoute"
            class="text-white font-semibold centerText"
            style="font-size: 1.2em; font-family: Arial"
          >
            A
          </span>
          <span v-else class="text-icon-not-active centerText" style="font-size: 1.2em; font-family: Arial"> A </span>
        </router-link>
      </el-tooltip>
    </div>
  </aside>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { Clock } from '@element-plus/icons-vue'
import IconList from '@/assets/svg/icon-list.svg'
import IconListActive from '@/assets/svg/list-sidebar-active.svg'
import IconAssets from '@/assets/svg/icon-assets.svg'
import IconAssetsActive from '@/assets/svg/icon-assets-active.svg'
import IconDashboard from '@/assets/svg/dashboard-sidebar.svg'
import IconDashboardActive from '@/assets/svg/dashboard-sidebar-active.svg'
import IconActivityActiv from '@/assets/svg/icon-activity-activ.svg'
import IconActivityGray from '@/assets/svg/icon-activity-gray.svg'
import IconActivityPipeLine from '@/assets/svg/icon-pipeline-activ.svg'
import IconPipeLine from '@/assets/svg/icon-pipeline.svg'
import IconUserActive from '@/assets/svg/leads-active.svg'
import IconUserFade from '@/assets/svg/leads-fade.svg'
import IconUser from '@/assets/svg/icon-empty-users.svg'
import IconLeads from '@/assets/svg/icon-leads.svg'
import IconLeadsActive from '@/assets/svg/icon-leads-active.svg'
import IconLogs from '@/assets/svg/icon-logs.svg'
import IconLogsActive from '@/assets/svg/icon-logs-active.svg'
import IrisIconReverse from '@/assets/svg/iris-icon-reverse.svg'
import IconProspectActive from '@/assets/svg/prospect-active.svg'
import IconProspect from '@/assets/svg/prospect-fade.svg'
import ROLE from '../../enums/roles'

export default {
  name: 'SwdSideBar',
  components: {
    Clock,
  },

  setup() {
    const store = useStore()

    const route = useRoute()

    const memberId = store.state.globalComponents.clientId
    const isClientRole = computed(() => getType.value === ROLE.CLIENT)

    const getRouteName = computed(() => {
      return route.name
    })
    const getActiveListOfHouseholds = computed(() => {
      return (
        getRouteName.value === `${getType.value}/all` ||
        getRouteName.value === `${getType.value}/clients` ||
        getRouteName.value === `${getType.value}/opportunities` ||
        getRouteName.value === `${getType.value}/list-of-advisors`
      )
    })

    const getActivedefault = computed(() => {
      return (
        getRouteName.value === `${getType.value}/account` ||
        getRouteName.value === `${getType.value}/transactions` ||
        getRouteName.value === `${getType.value}/position` ||
        getRouteName.value === `${getType.value}/tcf` ||
        getRouteName.value === `${getType.value}/security` ||
        getRouteName.value === `${getType.value}/profile`
      )
    })

    const isShowSideBar = computed(() => {
      if (!store.state.auth.isAuth) return false

      const hiddenRoles = [ROLE.CLIENT, ROLE.LEAD, ROLE.ADMIN, ROLE.CEO]

      return !hiddenRoles.includes(getType.value)
    })

    const getType = computed(() => {
      if (route.meta.type) return route.meta.type
      return store.state.globalComponents.role
    })

    const isSchwabRouteAllowed = computed(() =>
      [
        `${getType.value}/account`,
        `${getType.value}/transactions`,
        `${getType.value}/position`,
        `${getType.value}/security`,
        `${getType.value}/tcf`,
        `${getType.value}/profile`,
      ].includes(getRouteName.value)
    )

    const isAnnuityRoute = computed(() => getRouteName.value === 'annuity')

    const isLeadRoute = computed(() => {
      return (
        getRouteName.value === `${getType.value}/all-leads` ||
        getRouteName.value === `${getType.value}/active-leads` ||
        getRouteName.value === `${getType.value}/deactivated-leads`
      )
    })

    const leadIcon = computed(() => (isLeadRoute.value ? IconUserActive : IconUserFade))

    return {
      IconList,
      IconAssets,
      IconDashboard,
      IconDashboardActive,
      IconListActive,
      getRouteName,
      getActiveListOfHouseholds,
      memberId,
      getActivedefault,
      isClientRole,
      IconActivityGray,
      IconActivityActiv,
      IconActivityPipeLine,
      IconPipeLine,
      IconUserActive,
      IconUserFade,
      IconUser,
      IconAssetsActive,
      IconLeads,
      IconLeadsActive,
      IconLogs,
      IconLogsActive,
      IrisIconReverse,
      route,
      isShowSideBar,
      getType,
      isSchwabRouteAllowed,
      isAnnuityRoute,
      isLeadRoute,
      leadIcon,
      IconProspectActive,
      IconProspect,
    }
  },
}
</script>

<style lang="css" scoped>
.centerText {
  text-align: center;
}

.active {
  background-color: rgba(103, 116, 148, 0.1);
}

.item:hover {
  background-color: rgba(103, 116, 148, 0.1);
}

.minWidth {
  min-width: 67px;
}

/* Tooltip styling */
.el-tooltip {
  position: relative;
}

.el-tooltip .el-tooltip__popper {
  font-size: 12px; /* Adjust tooltip text size */
  max-width: 120px; /* Adjust tooltip width */
  padding: 5px 8px; /* Adjust padding inside the tooltip */
  background-color: rgba(0, 0, 0, 0.7); /* Set background color */
  border-radius: 4px;
}

.el-tooltip:hover .box-item {
  background-color: rgba(103, 116, 148, 0.1); /* Same hover effect as menu items */
}

/* Tooltip box item */
.box-item {
  width: 50px;
  margin-top: -30px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .content {
    max-width: 800px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .content {
    max-width: 800px;
    margin: 0 auto;
  }
}

/* Tooltip box item reduced size */
.tooltip-base-box {
  width: 160px;
  font-size: 12px; /* Reduced font size for smaller tooltips */
}

.tooltip-base-box .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltip-base-box .center {
  justify-content: center;
}

.tooltip-base-box .box-item {
  width: 40px;
  margin-top: -10px; /* Reduced margin */
}
</style>
