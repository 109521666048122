<template>
  <div class="flex w-full ">
    <SwdSideBar />
    <div class="flex flex-col w-[95%]">
      <Header />
      <router-view />
    </div>
  </div>
</template>

<script>
import SwdSideBar from '@/components/Global/SwdSideBar.vue'

import Header from '@/components/Header/Header.vue'

export default {
  name: 'SuperAdminHome',

  components: {
    SwdSideBar,
    Header,
  },
}
</script>
